import { Tag } from "components/page/newHeader/styles/HeaderStyles";
import {
  CarouselContainerWithScrollBar,
  SectionContainer,
  SliderSectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import { PreTitle, TitleMed } from "components/rhombus-UI/theme/typography";
import { Link } from "gatsby";
import React from "react";
import { FreeMode, Mousewheel, Scrollbar } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import TextLinkV2 from "../common/TextLinkV2";
import {
  CardTitle,
  CaseStudyCard,
  relatedCaseStudySlideStyles,
} from "./styles/caseStudyStyles";
import { isDateWithinLast7Days } from "./util/caseStudyFunction";
import { styled } from "linaria/react";

const RelatedCaseStudies = ({
  relatedIndustry,
  fetchedCaseStudies,
  currentCaseStudyCompany,
  title = "Related Case Studies",
  preTitle = "",
}) => {
  const relatedCaseStudies = fetchedCaseStudies.filter(
    item =>
      item.node.frontmatter.industry === relatedIndustry &&
      item.node.frontmatter.companyName !== currentCaseStudyCompany
  );
  if (relatedCaseStudies.length < 3) return <></>;
  return (
    <SectionContainer
      style={{
        paddingLeft: "0",
        paddingRight: "0",
        overflow: "visible",
      }}
    >
      <SliderSectionInner>
        <TextContainer
          style={{
            textAlign: "center",
            alignItems: "center",
            padding: "0 1.25rem",
          }}
        >
          {!!preTitle && (
            <PreTitle color="var(--blue-500)">{preTitle}</PreTitle>
          )}
          <TitleMed>{title}</TitleMed>
        </TextContainer>
        <CarouselContainerWithScrollBar>
          <Swiper
            slidesPerView="auto"
            centeredSlides
            centeredSlidesBounds
            spaceBetween={20}
            freeMode
            scrollbar={{ draggable: true }}
            mousewheel={{
              releaseOnEdges: true,
              invert: false,
            }}
            modules={[Mousewheel, FreeMode, Scrollbar]}
          >
            {relatedCaseStudies.map(item => {
              const caseStudy = item.node.frontmatter;
              const slug = item.node.fields.slug;
              return (
                <SwiperSlide
                  key={item.node.id}
                  className={relatedCaseStudySlideStyles}
                >
                  <CaseStudyCard
                    as={Link}
                    to={slug}
                    style={{ width: "100%", height: "100%" }}
                    slider
                  >
                    <div className="card-image-wrapper">
                      <img
                        src={caseStudy.heroImage}
                        className="card-image"
                        alt=""
                      />

                      {caseStudy.cardLogo && (
                        <img src={caseStudy.cardLogo} className="logo" alt="" />
                      )}
                    </div>
                    <div className="inner">
                      <div
                        style={{
                          display: "flex",
                          gap: "0.5rem",
                          alignItems: "center",
                        }}
                      >
                        <PreTitle color="var(--blue-500)">
                          {caseStudy.industry}
                        </PreTitle>
                        {isDateWithinLast7Days(caseStudy.date) && (
                          <Tag>New</Tag>
                        )}
                        {caseStudy.featured && <Tag>Featured</Tag>}
                      </div>
                      <CardTitle
                        style={{
                          textAlign: "left",
                          fontSize: "24px",
                        }}
                        color="var(--blue-900)"
                      >
                        {caseStudy.pageTitle}
                      </CardTitle>
                      <div className="footer">
                        <TextLinkV2
                          title="Read Case Study"
                          color="var(--blue-500)"
                          path={slug}
                        />
                      </div>
                    </div>
                  </CaseStudyCard>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </CarouselContainerWithScrollBar>
      </SliderSectionInner>
    </SectionContainer>
  );
};

export default RelatedCaseStudies;
