import RelatedCaseStudies from "components/case-study/RelatedCaseStudies";
import TrialBanner from "components/common/footer-banners/TrialBanner";
import AboutSection from "components/hybrid-event/AboutSection";
import HybridEventDetails from "components/hybrid-event/HybridEventDetails";
import HybridEventHero from "components/hybrid-event/HybridEventHero";
import PartnerLogos from "components/hybrid-event/PartnerLogos";
import { formatISODateWithTimeZone } from "components/hybrid-event/util";
import GlobalLayout from "components/page/GlobalLayout";
import { graphql } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";

const hybridEvent = ({ data }) => {
  const {
    markdownRemark: {
      frontmatter: {
        metaTitle,
        metaDescription,
        ogImage,
        formId,
        preTitle,
        title,
        dateInfo: { startDate, timeZone },
        description,
        locations: { locationOnline, locationInPerson },
        note,
        coHostedLogos,
        partnerLogos,
        eventDetails: { eventSummary, eventHighlights, eventImage },
        aboutRhombus: {
          aboutRhombusTitle,
          aboutRhombusDescription,
          aboutRhombusLink,
          aboutRhombusImage,
        },
        aboutPartners,
        relatedCaseStudies: {
          relatedCaseStudiesEnabled,
          relatedCaseStudiesIndustry,
        },
      },
    },
    allMarkdownRemark: { edges: _caseStudies },
  } = data;

  const logos = partnerLogos.map(item => item.logo);
  const _aboutPartners = aboutPartners.map(item => {
    const partner = item.aboutPartner;
    return {
      title: partner.aboutPartnerTitle || "",
      description: partner.aboutPartnerDescription || "",
      link: partner.aboutPartnerLink || "",
      image: partner.aboutPartnerImage || "",
      logo: {
        src: partner.aboutPartnerLogo?.aboutLogoSrc || "",
        alt: partner.aboutPartnerLogo?.aboutLogoAlt || "",
        width: partner.aboutPartnerLogo?.aboutLogoWidth || "",
        height: partner.aboutPartnerLogo?.aboutLogoHeight || "",
      },
      name: partner.partnerName || "",
    };
  });

  return (
    <GlobalLayout color="var(--gradient-light)">
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta property="og:image" content={ogImage} />
      </Helmet>
      <HybridEventHero
        preTitle={preTitle}
        title={title}
        date={startDate}
        timeZone={timeZone}
        description={description}
        formId={formId}
        locationInPerson={locationInPerson}
        locationOnline={locationOnline}
        note={note}
        logos={logos && coHostedLogos && logos}
      />
      {logos && logos.length >= 3 && !coHostedLogos && (
        <PartnerLogos logos={logos} />
      )}
      <HybridEventDetails
        highlights={eventHighlights}
        image={eventImage}
        summary={eventSummary}
      />
      <AboutSection
        title="About Rhombus"
        rows={[
          {
            title: aboutRhombusTitle,
            description: aboutRhombusDescription,
            link: aboutRhombusLink,
            image: aboutRhombusImage,
            name: "Rhombus",
          },
        ]}
      />
      <AboutSection title="About Our Partners" rows={_aboutPartners} />
      {relatedCaseStudiesEnabled && (
        <RelatedCaseStudies
          relatedIndustry="Education"
          fetchedCaseStudies={_caseStudies}
          preTitle="CUSTOMER SUCCESS"
          title={`${relatedCaseStudiesIndustry} Leaders Trust Rhombus`}
        />
      )}
      <TrialBanner />
    </GlobalLayout>
  );
};

export default hybridEvent;

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      fields {
        slug
      }
      frontmatter {
        draft
        metaTitle
        metaDescription
        ogImage
        formId
        preTitle
        title
        dateInfo {
          startDate
          timeZone
        }
        description
        locations {
          locationOnline
          locationInPerson
        }
        note
        coHostedLogos
        partnerLogos {
          logo {
            logoSrc
            logoAlt
            logoWidth
            logoHeight
          }
        }
        eventDetails {
          eventSummary
          eventHighlights
          eventImage
        }
        aboutRhombus {
          aboutRhombusTitle
          aboutRhombusDescription
          aboutRhombusLink
          aboutRhombusImage
        }
        aboutPartners {
          aboutPartner {
            aboutPartnerLogo {
              aboutLogoSrc
              aboutLogoAlt
              aboutLogoWidth
              aboutLogoHeight
            }
            partnerName
            aboutPartnerTitle
            aboutPartnerDescription
            aboutPartnerImage
            aboutPartnerLink
          }
        }
        relatedCaseStudies {
          relatedCaseStudiesEnabled
          relatedCaseStudiesIndustry
        }
      }
    }
    allMarkdownRemark(
      limit: 20
      sort: { fields: [frontmatter___date], order: ASC }
      filter: {
        frontmatter: { templateKey: { eq: "case-study" }, draft: { ne: true } }
      }
    ) {
      totalCount
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            date
            companyLogo
            heroImage
            companyName
            pageTitle
            featured
            industry
            cardLogo
          }
        }
      }
    }
  }
`;
