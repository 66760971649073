import FadeInXAxis from "components/common/animated-sections/FadeInXAxis";
import IconCheckCircle from "components/common/icon-components/IconCheckCircle";
import {
  SectionCol,
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import {
  FancyTitle,
  MainParagraph,
  TitleSmall,
} from "components/rhombus-UI/theme/typography";
import { styled } from "linaria/react";
import React from "react";
import { Converter } from "showdown";

const DetailSectionInner = styled(SectionInner)`
  flex-direction: column;
  gap: 5rem;
`;

const DetailContainer = styled.div`
  display: flex;
  gap: 2.5rem;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const ImageCol = styled(SectionCol)`
  @media (max-width: 1024px) {
    width: 100%;
    justify-content: center;
  }
`;

const DetailImage = styled.img`
  width: 100%;
  max-width: 665px;
  flex-shrink: 0;
  object-fit: contain;
  object-position: top;
`;

const DetailColumn = styled(SectionCol)`
  flex-direction: column;
  gap: 1.25rem;
  @media (max-width: 1024px) {
    width: 100%;
    align-items: center;
  }
`;

const Highlights = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  margin: 0;
  gap: 1.25rem;
`;

const Highlight = styled.li`
  display: flex;
  gap: 0.75rem;
  font-size: 16px;
  svg {
    margin-top: 5px;
  }
`;

const HybridEventDetails = ({ image, summary, highlights }) => {
  const converter = new Converter();
  const _summary = converter.makeHtml(summary);

  return (
    <SectionContainer>
      <DetailSectionInner>
        <FancyTitle style={{ textAlign: "center" }}>Event Details</FancyTitle>
        <DetailContainer>
          <ImageCol>
            <DetailImage src={image} />
          </ImageCol>
          <DetailColumn>
            <TextContainer width="665px">
              <div dangerouslySetInnerHTML={{ __html: _summary }} />
            </TextContainer>
            <TextContainer style={{ gap: "1.25rem" }} width="665px">
              <TitleSmall style={{ fontSize: "20px" }}>
                What to expect
              </TitleSmall>
              <Highlights>
                {highlights.map((highlight, index) => {
                  const _highlight = converter.makeHtml(highlight);
                  return (
                    <FadeInXAxis key={index}>
                      <Highlight>
                        <IconCheckCircle />
                        <div dangerouslySetInnerHTML={{ __html: _highlight }} />
                      </Highlight>
                    </FadeInXAxis>
                  );
                })}
              </Highlights>
            </TextContainer>
            <TextContainer width="665px">
              <MainParagraph>
                <b>Don’t miss out!</b> <a href="#form">Reserve your spot.</a>
              </MainParagraph>
            </TextContainer>
          </DetailColumn>
        </DetailContainer>
      </DetailSectionInner>
    </SectionContainer>
  );
};

export default HybridEventDetails;
