import {
  SectionContainer,
  SectionInner,
} from "components/rhombus-UI/theme/containers";
import useIsMobile from "hooks/useIsMobile";
import { styled } from "linaria/react";
import React, { useCallback } from "react";

const LogoSectionContainer = styled(SectionContainer)`
  background: var(--gradient-light);
  padding-top: 0;
`;

const LogoSectionInner = styled(SectionInner)`
  -webkit-mask: ${props =>
    props.animated
      ? "linear-gradient(90deg, transparent, #fff 10% 80%, transparent)"
      : "unset"};
`;

const LogosContainer = styled.div`
  display: flex;
  width: ${props => props.width};
  justify-content: center;
  gap: 5rem;
  animation: scrollAnimation 40s linear infinite;
  @keyframes scrollAnimation {
    to {
      transform: ${props =>
        props.animated ? "translateX(calc(-50% - 2.5rem))" : "unset"};
    }
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Logo = styled.img``;

const PartnerLogos = ({ logos = [] }) => {
  const { isMobile } = useIsMobile(1024);
  if (logos.length === 0) {
    return <></>;
  }
  const renderLogoHandler = useCallback(() => {
    let renderDetails = { logosToRender: logos, animated: false };
    if (logos.length > 5 || isMobile) {
      renderDetails.logosToRender = [...logos, ...logos, ...logos];
      renderDetails.animated = true;
    }
    return renderDetails;
  }, [logos, isMobile]);

  const { logosToRender, animated } = renderLogoHandler();

  return (
    <LogoSectionContainer>
      <LogoSectionInner animated={animated}>
        <LogosContainer
          animated={animated}
          width={animated ? "max-content" : "100%"}
        >
          {logosToRender.map((logo, index) => {
            const {
              logoSrc: src,
              logoAlt: alt,
              logoWidth: width,
              logoHeight: height,
            } = logo;
            return (
              <LogoWrapper key={`${alt}-${index}`}>
                <Logo src={src} alt={alt} height={height} width={width} />
              </LogoWrapper>
            );
          })}
        </LogosContainer>
      </LogoSectionInner>
    </LogoSectionContainer>
  );
};

export default PartnerLogos;
